<template>
  <div id="petlist-view">
    
    <section class="bg-light">
      <main>
        
      </main>
      <div class="col-md-12">
        <div class="findgft">
         <router-link to='/wishlist' class="sm_megamenu_head sm_megamenu_drop "
                            id="sm_megamenu_286" href="">  <h1 class="display-4">Pet Wishlist</h1></router-link>
                           
        </div>
        <div class="d-flex justify-content-center">
          <img src="@/assets/site/images/loader.gif" v-if="loader===true" class="img-fluid  " alt />
        </div>
       
      </div>
      <div class="container-fluid pt-5">
 

      </div>
      <!-- <div class="container-fluid">
         <toggle-button :value="wishlist_status== 'Private' ? true: false" @change="changeStatus(wishlist_status)"     color="#c8730e"  />
        <hr />
      </div> -->
      <p v-if="imageshow">
       <span class="d-flex justify-content-center"><img class="img-fluid text-center" src="@/assets/site/images/Group1.svg" alt=""></span>   
        </p>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <ul>
              <li v-for="pet in pets" class="sm_megamenu_title ml-4 my-3" @click="showOccasionList(pet)">
                <!-- <router-link v-for="contact in contacts" class="sm_megamenu_title ml-4 my-3" to="occasion_wishlist"> -->
                  <h2>
                    <span class="col-md-3">
                      <img :src="pet.image" width="30" height="40" class="img-fluid"  alt=""> 
                    </span>  
                    {{pet.name}}
                  
                  </h2>
                <!-- </router-link> -->
                
              </li>
            </ul>
          </div>
          <div class="col-md-9">
            <petwishlist-view v-if="show" v-bind:wishlistproducts="wishlistproducts"></petwishlist-view>
          </div>
        </div>
      </div>


      <div class="container">
        <hr />
      </div>


    </section>

    <!-- End Footer -->

  </div>
</template>
<script>
import PetwishlistView from '@/views/site/user/PetwishlistView.vue';
 

    export default {
        name: "PetslistView",
        data() {
            return {
                loader: true,
                errors: [],
                pages: [],
                pets: [],
                wishlistproducts: [],
                imageshow: false,
                wishlist_status:null,
                show: false,
            };
        },
        components: { PetwishlistView },
        created() {
           
            this.getWishlistProducts();

        },
        methods: {
             getWishlistProducts() {
                this.errors = [];
                //this.loader = true;
                var token = localStorage.getItem("userData");
                var userdata_array = JSON.parse(token);
                var user_id = userdata_array.user.id;
                //  var api_token = userdata_array.token;
                let method_type = "";
                let fetch_url = "";
                method_type = "post";
                fetch_url = process.env.VUE_APP_URL + "customer/userwishlist/getwishlistProducts";
                fetch(fetch_url, {
                        method: method_type,
                        headers: {
                            "content-type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",

                        },
                        body: JSON.stringify({
                            wishlist_type:'Pet',
                            user_id: user_id,
                        }),
                    })
                    .then(res => res.json())
                    .then(data => {
                        this.loader = false;
                        this.errors = [];
                        this.wishlistproducts = data.wishlist;
                        this.wishlist_status=data.wishlist_status;
                        if (this.wishlistproducts.length == 0) {
                            this.imageshow = true;
                        }
                        this.pets = data.PetList;
                    })

            },
          showWishlist(contact){
            // this.$router.replace({
            //     name: 'FriendswishlistView',
            //     params: {
            //       wishlist:contact.wishlist,
            //     }
            // })
            this.show = true;
            this.wishlistproducts = contact.wishlist;
          } , 
          showOccasionList(contact){
            var page_name = this.$route.meta.title;
            var path = this.$route.path;
            this.$router.replace({
                name: 'Occasionwishlist',
                params: {
                  occasion_list:contact.occasion_lists,
                  page_name: page_name,
                  path: path,
                }
            })
            // this.show = true;
            // this.wishlistproducts = contact.wishlist;
          } ,  
                    
        }
    }
</script>